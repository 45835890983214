
function useBilderUndGedichte() {
    return [
        {
            bildLink: 'Bilder/01_TakeMeInYourEyes_Thumb.JPG',
            width: 194,
            bildTitel: 'Take me in your Eyes (06/87)',
            gedicht: ['Kosmischer Traum …', '', 
            'In Deinen Augen',
            'Sterne sehen',
            'Die Sonne und den Mond!',
            'In Deinen Augen',
            'HÄNGENBLEIBEN',
            'bis',
            'dass man darin wohnt!']
        },
        {
            bildLink: 'Bilder/02_Rueckendeckung_Thumb.JPG',
            width: 194,
            bildTitel: 'Rückendeckung (02/88)',
            gedicht: ['RÜCKENDECKUNG','',
            'Entwurzelt … kraftlos',
            'Rückendeckung heiß ersehnt',
            'vorbeugen dem Fall',]
        },
        {
            bildLink: 'Bilder/03_GoAndBe_Thumb.JPG',
            width: 194,
            bildTitel: 'Go and be... (04/88)',
            gedicht: ['Fuß - Spuren', '',
            'Barfuß auf sich zu',
            'Schlüsselerlebnis EGO',
            'Standfestigkeit wächst']
        },
        {
            bildLink: 'Bilder/Freiheit_8808_Thumb.JPG',
            width: 194,
            bildTitel: 'Freiheit (08/88)',
            gedicht: ['Freiheit-s-Tanz', '',
            'Freiheitssuche Tanz',
            'Schatten eilen dir voraus',
            'Bewegung tut Not']
        },
        {
            bildLink: 'Bilder/04_Einsturzgefahr_Thumb.JPG',
            width: 194,
            bildTitel: 'Einsturzgefahr (02/88)',
            gedicht: ['EINSTURZGEFAHR','',
            'Mein Körper ist … mein Gebäude',
            'Mein Gebäude ist …  meine Wohnung',
            'In meiner Wohnung bin … ICH',
            'ICH wohne in … meinem Körper',
            'Mein Körper ist…',
            'Gebäudezustand ist verschlissen',
            'Mißachtet wurden seine Wunden',
            'Längst ist die Außenhaut zerrissen',
            'Standfestigkeit ist aus dem Lot',
            'Einsturzgefahr … die offen droht',
            'Tot scheint auch schon die Außenwelt',
            'Und … was mich noch am LEBEN hält',
            'Sind nicht nur Stab und Krücke',
            '',
            'Es sind die Regenbogenfarben',
            'Von denen Kraft ich pflücke',
            'Und kühle meine Narben',
            'DU - großer schwarzer Vogel',
            'Mußt eine Weile noch verzichten',
            'Erst',
            'Wenn der letzte Farbenquell',
            'In mir erloschen ist',
            'Erst dann …',
            'Darfst DU in meinem Haus',
            'Dein dunkelwarmes Nest errichten']
        },
        {
            bildLink: 'Bilder/05_Selbsthilfe_mod2_Thumb.JPG',
            width: 194,
            bildTitel: 'Selbsthilfe (02/89)',
            gedicht: ['',]
        },
        {
            bildLink: 'Bilder/06_SchwebendEntruecken_Thumb.JPG',
            width: 194,
            bildTitel: 'Schwebend entRÜCKEN (02/89)',
            gedicht: ['',]
        },
        {
            bildLink: 'Bilder/07_Grenzueberschreitung_Thumb.JPG',
            width: 259,
            bildTitel: 'Grenzüberschreitungen (01/89)',
            gedicht: []
        },
        {
            bildLink: 'Bilder/DrunterUndDrueber_9109_Thumb.JPG',
            width: 194,
            bildTitel: '..drunter und drüber.. (09/91)',
            gedicht: []
        },
        {
            bildLink: 'Bilder/StarkeSchwingen_0401_Thumb.JPG',
            width: 259,
            bildTitel: 'was ich dir wünsche ... starke Schwingen! (01/04)',
            gedicht: []
        },
        {
            bildLink: 'Bilder/Unterwegs_8908_Thumb.JPG',
            width: 194,
            bildTitel: '.. unterwegs .. (08/89)',
            gedicht: []
        },
    ]
}

export default useBilderUndGedichte;