import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import useBilderUndGedichte from '../Hooks/useBilderUndGedichte';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  elevation: 3
}));

const BilderUndGedichte = () => {
  const bilderUndGedichteInfos = useBilderUndGedichte()
    return (
      <div>
          <h2>Bilder und Gedichte</h2>
          <p style={{padding: 10 }}>Malerei ist für mich keine dekorative Kunst, sondern die Sichtbarmachung von inneren Bewegungen. <br/>    
            Ich benutze Farbe und Form als Mittler von Gedanken und Gefühlen. Bediente ich mich hier zunächst der gegenständlichen Malweise, so hatte ich zunehmend das Bedürfnis, mich von dieser "vernünftigen, ordentlichen" Ausdrucksweise zu lösen. Es gelang mir mehr und mehr, meinen inneren Welten durch einen abstrakten Malstil Ausdruck zu verleihen. <br/>
            Heute gehe ich mit beiden Arten der Malerei um --- beide zusammen ergeben erst ein GANZES: Aus einem "entweder - oder" entstand ein "sowohl - als auch".
          Hier seht Ihr eine kleine Auswahl meiner Bilder und Gedichte.</p>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {bilderUndGedichteInfos.map((element) => {
            return <Grid key={element.bildTitel} xs={6}>
              <Item>
                <Grid>
                  <Grid>
                  <img src={element.bildLink} width="100%" alt=""/> <br/>
                    {element.bildTitel}
                  </Grid>
                  <Grid> {element.gedicht.map((zeile) => {
                    return <div>{zeile}<br/></div>
                  })}
                  </Grid>
              </Grid>
              </Item>
            </Grid>
          })}
        </Grid>
      </div>)
}

export default BilderUndGedichte