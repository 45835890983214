
function useAusstellungen() {
    return [
        {
            zeit: '12/1989',
            inhalt: 'Uni-Klinik Münster (Orthopädie)'
        },
        {
            zeit: '03/1991',
            inhalt: 'Medizinische Hochschule Hannover'
        },
        {
            zeit: '04/1991',
            inhalt: 'Ludwigshafener Tanztage'
        },
        {
            zeit: '06/1994',
            inhalt: 'Rathaus Everswinkel'
        },
        {
            zeit: '09/1996',
            inhalt: '„Theater am Wall“, Warendorf'
        },
        {
            zeit: '06/1998',
            inhalt: '„Kunst in het Volkspark“, Enschede'
        },
        {
            zeit: '10/2000',
            inhalt: 'Landvolkshochschule Warendorf'
        },
        {
            zeit: '11/2003',
            inhalt: 'Begleitende Ausstellung zu „FRIDA“ (Frida Kahlo – Der Film und Vortrag)'
        },
        {
            zeit: '07/2004',
            inhalt: '„Kulturey“ Kultursommer Warendorf'
        },
        {
            zeit: '02/2005',
            inhalt: 'Volksbank Hellweg eG, Warstein'
        },
        {
            zeit: '02/2008',
            inhalt: '"Friseur und Kunst", Paderborn'
        },
        {
            zeit: '05/2011',
            inhalt: 'Freckenhorster Bürgerhaus'
        },
    ]
}

export default useAusstellungen;