import React from 'react';
import './App.css';
import Navbar1 from './Components/Navbar1';
import Start from './Components/Start';
import { createTheme, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom"
import Kontakt from "./Components/Kontakt"
import Ausstellungen from "./Components/Ausstellungen"
import BilderUndGedichte from "./Components/BilderUndGedichte"
import Datenschutz from './Components/Datenschutz';
import UeberMich from './Components/UeberMich';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#F3910C',
      },
      secondary: {
        main: '#4B4B4B',
      },
    },
  });

  return (
      <ThemeProvider theme={theme}>
        <div className="App">
        <Navbar1/>
          <Routes>
            <Route exact path="/" element={<Start/>} />
            <Route exact path='UeberMich.html' element={UeberMich()}/>
            <Route exact path='Ausstellungen.html' element={Ausstellungen()}/>
            <Route exact path='BilderUndGedichte.html' element={BilderUndGedichte()}/>
            <Route exact path='Kontakt.html' element={Kontakt()}/>
            <Route exact path='Datenschutz.html' element={Datenschutz()}/>
          </Routes>
        </div>
      </ThemeProvider>
  );
}

export default App;
