export const pages = [
    {
        name: 'Über Mich',
        path: 'UeberMich.html',
        componentname: 'UeberMich'
    },
    {
        name: 'Ausstellungen',
        path: 'Ausstellungen.html',
        componentname: 'Ausstellungen'
    },
    {
        name: 'Bilder und Gedichte',
        path: 'BilderUndGedichte.html',
        componentname: 'BilderUndGedichte'
    },
    {
        name: 'Kontakt',
        path: 'Kontakt.html',
        componentname: 'Kontakt'
    },
    {
        name: 'Datenschutz',
        path: 'Datenschutz.html',
        componentname: 'Datenschutz'
    }
];