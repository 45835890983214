import React from 'react';

const Kontakt = () => {
    return <div><h2>Kontakt / Impressum</h2>
    <p>Irmgard Lütke-Beckmann<br/>
    Nordstr. 9<br/>
    48231 Warendorf<br/></p>
    <p>Telefon: 0160 5901699</p>
    <a href='mailto:irmilb123@gmail.com'>irmilb123@gmail.com</a>
    </div>
}

export default Kontakt