import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import useAusstellungen from '../Hooks/useAusstellungen';

const Ausstellungen = () => {
	const ausstellungen = useAusstellungen();

    return <div>
        <h2>Ausstellungen</h2>

        <Container maxWidth='sm'>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    </TableHead>
                    <TableBody>
                    {ausstellungen.map((row) => (
                        <TableRow
                        key={row.zeit}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.zeit}
                            </TableCell>
                            <TableCell align="right">
                                {row.inhalt}
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    </div>
}

export default Ausstellungen