
function useLbeneslaufInfos() {
    return [
        {
            zeit: '1966-69',
            inhalt: 'Ausbildung zur Industriekauffrau'
        },
        {
            zeit: '1969-82',
            inhalt: 'Berufstätig bei der IHK, Münster'
        },
        {
            zeit: '1982',
            inhalt: '1. OP im Skoliosezentrum Bad Wildungen'
        },
        {
            zeit: '1984',
            inhalt: '2. OP im Skoliosezentrum Bad Wildungen'
        },
        {
            zeit: 'seit 1986',
            inhalt: 'Diverse Ausstellungen / Kunstaktionen',
            link: 'Ausstellungen.html'
        },
    ]
}

export default useLbeneslaufInfos;