
function useIrmisAlter() {
    const geburtstagTag = 12;
    const geburtstagMonat = 3;
    const geburtstagJahr = 1950;
    const geburtstagOp = 1982;

    const date = new Date();
    let alterAlt = date.getFullYear() - geburtstagJahr;
    let alterJung = date.getFullYear() - geburtstagOp;
    
    const decrementAge = () => {
        alterAlt -= 1;
        alterJung -= 1;
    }

    // Vor dem Geburtsmonat
    if( date.getMonth() < geburtstagMonat) {
        decrementAge();    
    }
    // Im Geburtsmonat, vor dem Geburtstag
    else if (date.getMonth() === geburtstagMonat && date.getDate() < geburtstagTag)
    {
        decrementAge();
    }

    return {
        alterAlt: alterAlt,
        alterJung: alterJung
    }
}

export default useIrmisAlter;