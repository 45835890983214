import React from 'react';
import useIrmisAlter from '../Hooks/useIrmisAlter'
import useLbeneslaufInfos from '../Hooks/useLebenslaufInfos';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const UeberMich = () => {
    const navigate = useNavigate();
    const lebenslaufInfos = useLbeneslaufInfos();
    const {alterJung, alterAlt} = useIrmisAlter();

    const getLLInhalt = (llInfo) => {
        if(llInfo.link) {
            return <div> 
                {llInfo.inhalt}&nbsp;
                <button onClick={() => {navigate(llInfo.link)}}> <ArrowForwardIcon/> </button> 
            </div>
        }
        else{
            return llInfo.inhalt
        }
    }

    return <div>
        <h2>Über mich</h2>
        <img alt='Irmgard Lütke-Beckmann' src="Bilder/Irmi.JPG" higth="200px" width="200px"/>
        <p>Ich bin {alterAlt} Jahre alt und {alterJung} Jahre jung und lebe in Warendorf.<br/>
        Seit 1982 setze ich mich aktiv mit dem Krankheitsbild „Skoliose“ auseinander.</p>
        <p>Identitätsverlust, Neuorientierung.</p>
        <p>Autodidaktisch neue Wege der Ausdrucksmöglichkeit gesucht und gefunden.</p>
        <p>Nehmen Sie Kontakt mit mir auf!&nbsp;<button onClick={() => {navigate("/Kontakt.html")}}><ArrowForwardIcon/></button> </p>
        
        {/* https://mui.com/material-ui/react-table/ */}
        <Container maxWidth='sm'>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    </TableHead>
                    <TableBody>
                    {lebenslaufInfos.map((row) => (
                        <TableRow
                        key={row.zeit}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.zeit}
                            </TableCell>
                            <TableCell align="right">
                                {getLLInhalt(row)}
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    </div>
}

export default UeberMich